import { graphql } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import UnderlinedButton from '../components/Button/UnderlinedButton';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import ContactFormView from '../views/Contact/ContactFormView';
import DealersView from '../views/Contact/DealersView/Dealers';

const translations = [
  {
    locale: 'fr',
    url: '/contact/',
  },
];

export default function ContactEnPage({ data, pageContext: { langKey } }) {
  return (
    <Layout translations={translations}>
      <PageHeader>Contact</PageHeader>

      <section className="container mt-16">
        <div className="grid lg:grid-cols-2 gap-8 wide-content-contained">
          <div className="space-y-4 bg-white p-4 sm:p-8 lg:p-12 xl:p-20 2xl:p-32">
            <Heading level={2} className="!mt-0">
              <FormattedMessage defaultMessage="Coordonnées" />
            </Heading>

            <address className="not-italic">
              1244, route 165 <br />
              Thetford Mines <br />
              Quebec G6H 1M6 <br />
              Canada
            </address>

            <p>
              <UnderlinedButton href="tel:+14183359471">
                +1 418-335-9471
              </UnderlinedButton>
            </p>

            <p>
              <UnderlinedButton href="mailto:michel@pelleringuitars.com">
                michel@pelleringuitars.com
              </UnderlinedButton>
            </p>
          </div>

          <div className=" p-4 sm:p-8 lg:p-12 xl:p-20 2xl:p-32">
            <Heading level={2} className="!mt-0">
              <FormattedMessage defaultMessage="Courriel" />
            </Heading>

            <ContactFormView />
          </div>
        </div>
      </section>

      <section className="container">
        <Heading level={2}>
          <FormattedMessage defaultMessage="Détaillants" />
        </Heading>

        <DealersView data={data} langKey={langKey} />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query {
    allSanityDealer {
      countries: group(field: address___country) {
        dealers: nodes {
          name
          website
          phone
          notes {
            _rawFr(resolveReferences: { maxDepth: 10 })
            _rawEn(resolveReferences: { maxDepth: 10 })
          }
          email
          id
          address {
            addressLine1
            addressLine2
            city
            countryCode: country
            postalCode
            regionCode: region
          }
        }
        countryCode: fieldValue
      }
    }
  }
`;
